<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BCard, BButton, BModal, BFormFile, BProgress } from 'bootstrap-vue'
export default {
  components: {
    BCard, BButton, BModal, BFormFile, BProgress
  },
  data() {
    return {
      pageArrayStock: [], // for Frontend Search
      pageArray: [],

      editModal: false,
      editObject: {
        billboardId: '',
        billboardName: '',
        billboardImage: '',
      },

      filterName: '',

      mediaModal: false,
      viewMedia: '',
      viewMediaType: '',
      imageView: '',

      percentValue: 0
    }
  },
  async created() {
    await this.mountedData()
  },
  methods: {
    async mountedData() {

      // Clear Variables..
      this.editModal = false
      this.mediaModal = false
      this.imageView = ''
      this.filterName = ''
      this.percentValue = 0

      // Get List
      await this.$http.get('features/billboard/list')
          .then(response => {
            this.pageArrayStock = this.pageArray = response.data
          }).catch(() => { alert('ERROR! PLEASE CONTACT TO DEVELOPER') })
    },
    async tableInteractive(item, index, type) {
      if (type === 'edit') {
        this.editObject = item
        this.editModal = true
      }
      if (type === 'delete') {
        await this.$http.get(`features/billboard/clear/${item.billboardId}`)
            .then(r => {
              this.mountedData()
            })
      }
    },
    async saveData(type) {
      if (type === 'update') {
        const formData = new FormData()
        formData.append('billboardId', this.editObject.billboardId)
        formData.append('billboardImage', this.editObject.billboardImage)
        formData.append('billboardName', this.editObject.billboardName)
        formData.append('billboardEnum', this.editObject.billboardEnum)

        const axiosConfig = {
          onUploadProgress: progressEvent => {
            this.percentValue = parseInt(Math.round( ( progressEvent.loaded / progressEvent.total ) * 100))
          }
        }

        await this.$http({
          method: 'PUT',
          url: 'features/billboard/update',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress : (progressEvent) => {
            this.percentValue = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
          },
        }).then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success, Please Wait..',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.mountedData()
            }).catch(error => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'ERROR',
                  icon: 'XIcon',
                  variant: 'danger',
                },
              })
            })
      }
    },
    async filterNow() {
      this.pageArray = this.pageArrayStock.filter(x => x.billboardName.toLocaleLowerCase().includes(this.filterName.toLocaleLowerCase()))
    },
    openMedia(mediaUrl, mediaType) {
      this.viewMedia = mediaUrl
      this.viewMediaType = mediaType
      this.mediaModal = true
    },
    imagePreview(event) {
      const fileReader = new FileReader()
      fileReader.onload = () => {
        this.imageView = fileReader.result
      }
      fileReader.readAsDataURL(event.target.files[0])
      this.editObject.billboardImage = event.target.files[0]
    },
    deleteImage() {
      this.editObject.billboardImage = ''
    }
  },
  watch: {
    filterName: {
      handler() {
        this.filterNow()
      }
    },
  }
}
</script>

<template>
  <section class="pb-2">
    <div class="tb-card">
      <a class="tb-page-title">Registered Users ({{pageArrayStock.length}})</a>
    </div>
    <div class="tb-card mt-2">
      <table class="tb-table">
        <thead>
        <tr>
          <th style="width: 5%"><input type="text" class="form-control" placeholder="#" disabled readonly></th>
          <th style="width: 15%"><input type="text" class="form-control" placeholder="Billboard File" disabled readonly></th>
          <th style="width: 50%"><input type="text" class="form-control" placeholder="Billboard Name" v-model="filterName"></th>
          <th style="width: 15%"><input type="text" class="form-control" placeholder="Billboard Key" readonly disabled></th>
          <th style="width: 15%"><input type="text" class="form-control" placeholder="Options" disabled readonly></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in pageArray">
          <td><a>{{ index + 1 }}</a></td>
          <td>
            <div v-if="item.billboardUrlExtension === 'video/mp4'" class="w-100 h-120-px bg-primary tb-flex-full tb-border-radius-0-5-rem tb-hover-pointer" @click="openMedia(item.billboardUrl, item.billboardUrlExtension)">
              <feather-icon icon="FilmIcon" size="32" class="text-white" />
            </div>
            <img v-else :src="item.billboardUrl" class="w-100 h-120-px tb-img-cover tb-border-radius-0-5-rem tb-hover-pointer" alt="" @click="openMedia(item.billboardUrl, item.billboardUrlExtension)">
          </td>
          <td><a>{{ item.billboardName }}</a></td>
          <td><a>{{ item.billboardEnum }}</a></td>
          <td>
            <button class="tb-button bg-primary" @click="tableInteractive(item, index, 'edit')">
              <span class="text-white">Edit</span>
            </button>
            <button class="tb-button bg-danger ml-1" @click="tableInteractive(item, index, 'delete')">
              <span class="text-white">Clear</span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <!-- Edit Modal -->
    <b-modal v-model="editModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <a class="tb-page-title">Update Billboard</a>
          <hr>
        </div>
        <div class="col-12">
          <label>Billboard Name</label>
          <input type="text" class="form-control" v-model="editObject.billboardName">
        </div>
        <div class="col-12 mt-1">
          <label>Billboard Image Url</label>
          <b-form-file
              @change="imagePreview"
              accept=".jpg, .jpeg, .png, .mp4, .JPEG, .PNG, .JPG, .MP4"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
          />
          <div v-if="imageView" class="col-12 mt-2 px-4">
            <img class="img-fluid tb-radius-5 shadow" :src="imageView" alt="">
            <button class="btn btn-block btn-danger btn-sm mt-1" @click="deleteImage()">Delete</button>
          </div>
        </div>
        <div class="col-12 text-center">
          <hr>
          <b-progress
            max="100"
            :value="percentValue"
          />
          <hr>
          <b-button variant="outline-primary" class="mt-1" @click="saveData('update')">Update & Close</b-button>
        </div>
      </div>
    </b-modal>

    <!-- Media Modal -->
    <b-modal v-model="mediaModal" hide-header hide-footer centered size="lg">
      <div class="row py-1">
        <div class="col-12 text-center">
          <video v-if="viewMediaType === 'video/mp4'" :src="viewMedia" controls class="w-100"></video>
          <img v-else :src="viewMedia" class="w-100 img-fluid" alt="">
        </div>
      </div>
    </b-modal>

  </section>
</template>
